import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter/src/TypesenseInstantsearchAdapter"
import { additionalSearchParameters } from "@jobshop/crossroads/utils/search/additionalSearchParameters"
import { checkLocationFacetFilter, checkLocationFilter } from "@jobshop/crossroads/utils/search/handleLocationFilters"
import { useTypesenseApiKey } from "~/composables/useTypesenseApiKey"

export async function useTypesenseSearchClient(searchConfig: SearchConfig) {
  const { public: { typesenseUrl } } = useRuntimeConfig()
  const { typesenseApiKey, typesenseApiKeyPending, typesenseApiKeyError } = await useTypesenseApiKey()

  // Required for handling nationwide jobs
  const { nationwideText } = searchConfig.filters

  // create typesense search client
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: typesenseApiKey.value,
      nodes: [
        {
          url: typesenseUrl,
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60,
    },
    additionalSearchParameters,
  })
  const searchClient = ref({
    ...typesenseInstantsearchAdapter.searchClient,
    search(requests) {
      const newRequests = requests.map((request) => {
        checkLocationFacetFilter(request, nationwideText)
        checkLocationFilter(request, nationwideText)

        return request
      })

      return typesenseInstantsearchAdapter.searchClient.search(newRequests)
    },
  })

  return { searchClient }
}
